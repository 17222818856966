// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE

import * as Cn from "re-classnames/src/Cn.bs.js";
import * as React from "react";
import * as TestID from "../helpers/TestID.bs.js";
import * as AlertDot from "./AlertDot.bs.js";
import * as TestUtils from "../helpers/TestUtils.bs.js";
import * as Touchable from "./Touchable.bs.js";
import * as Belt_Option from "bs-platform/lib/es6/belt_Option.js";
import * as Caml_option from "bs-platform/lib/es6/caml_option.js";

require("./SectionHeader.css");

function SectionHeader(Props) {
  var testID = Props.testID;
  var className = Props.className;
  var style = Props.style;
  var title = Props.title;
  var first = Props.first;
  var showAlert = Props.showAlert;
  var onClick = Props.onClick;
  var first$1 = Belt_Option.getWithDefault(first, false);
  var className$1 = Cn.make(/* :: */[
        "hui_section_header",
        /* :: */[
          Cn.ifTrue("hui_section_header_first", first$1),
          /* :: */[
            Cn.unpack(className),
            /* [] */0
          ]
        ]
      ]);
  var alert = showAlert !== undefined && showAlert ? /* array */[
      React.createElement(AlertDot.make, {
            testID: TestID.alertDot,
            className: "alert_dot",
            key: "dot"
          }),
      React.createElement("div", {
            key: "view",
            className: "flexer"
          })
    ] : null;
  var touchable = onClick !== undefined ? React.createElement(Touchable.make, {
          testID: "" + (String(TestID.seeAll) + (":" + (String(testID) + ""))),
          onClick: onClick,
          children: React.createElement("div", {
                className: "see_all"
              }, "See All")
        }) : null;
  var tmp = {
    className: className$1
  };
  if (style !== undefined) {
    tmp.style = Caml_option.valFromOption(style);
  }
  var div = React.createElement("div", tmp, React.createElement("div", {
            className: "title"
          }, title), alert, touchable);
  return TestUtils.withTestID(Caml_option.some(testID), div);
}

var make = SectionHeader;

var $$default = SectionHeader;

export {
  make ,
  $$default ,
  $$default as default,
  
}
/*  Not a pure module */
