/* TypeScript file generated from ActivityList.re by genType. */
/* eslint-disable import/first */


import * as React from 'react';

// tslint:disable-next-line:no-var-requires
const ActivityListBS = require('./ActivityList.bs');

import {ActivityExtended_t as Types_ActivityExtended_t} from '../../src/Types.gen';

import {Style_t as ReactDOMRe_Style_t} from '../../src/shims/ReactDOMRe.shim';

// tslint:disable-next-line:interface-over-type-literal
export type Props = {
  readonly activities: Types_ActivityExtended_t[]; 
  readonly className?: string; 
  readonly hasMore?: boolean; 
  readonly onEndReached?: (_1:void) => void; 
  readonly style?: ReactDOMRe_Style_t; 
  readonly testID?: string
};

export const $$default: React.ComponentType<{
  readonly activities: Types_ActivityExtended_t[]; 
  readonly className?: string; 
  readonly hasMore?: boolean; 
  readonly onEndReached?: (_1:void) => void; 
  readonly style?: ReactDOMRe_Style_t; 
  readonly testID?: string
}> = function ActivityList(Arg1: any) {
  const $props = {activities:Arg1.activities.map(function _element(ArrayItem: any) { return [ArrayItem.id, ArrayItem.activity.tag==="CheckinActivity"
    ? [/* CheckinActivity */618569084, [ArrayItem.activity.value.id, (ArrayItem.activity.value.object == null ? undefined : [(ArrayItem.activity.value.object.contact == null ? undefined : [ArrayItem.activity.value.object.contact.name, ArrayItem.activity.value.object.contact.imageUrl])]), (ArrayItem.activity.value.space == null ? undefined : [ArrayItem.activity.value.space.title]), (ArrayItem.activity.value.actor == null ? undefined : [ArrayItem.activity.value.actor.firstName]), ArrayItem.activity.value.time, ArrayItem.activity.value.verbEnum]]
    : ArrayItem.activity.tag==="DocsSentActivity"
    ? [/* DocsSentActivity */1039969026, [ArrayItem.activity.value.id, (ArrayItem.activity.value.target == null ? undefined : [(ArrayItem.activity.value.target.contact == null ? undefined : [ArrayItem.activity.value.target.contact.name, ArrayItem.activity.value.target.contact.imageUrl])]), (ArrayItem.activity.value.space == null ? undefined : [ArrayItem.activity.value.space.title]), (ArrayItem.activity.value.actor == null ? undefined : [ArrayItem.activity.value.actor.firstName]), (ArrayItem.activity.value.object == null ? undefined : [ArrayItem.activity.value.object.name]), ArrayItem.activity.value.time, ArrayItem.activity.value.verbEnum]]
    : [/* NoteAddedActivity */-752031939, [ArrayItem.activity.value.id, (ArrayItem.activity.value.target == null ? undefined : [(ArrayItem.activity.value.target.contact == null ? undefined : [ArrayItem.activity.value.target.contact.name, ArrayItem.activity.value.target.contact.imageUrl])]), (ArrayItem.activity.value.space == null ? undefined : [ArrayItem.activity.value.space.title]), (ArrayItem.activity.value.actor == null ? undefined : [ArrayItem.activity.value.actor.firstName]), (ArrayItem.activity.value.object == null ? undefined : [ArrayItem.activity.value.object.text, ArrayItem.activity.value.object.isVendorComment]), ArrayItem.activity.value.time, ArrayItem.activity.value.verbEnum]], ArrayItem.hideSpace, ArrayItem.contactPressed, ArrayItem.spacePressed]}), className:Arg1.className, hasMore:Arg1.hasMore, onEndReached:Arg1.onEndReached, style:Arg1.style, testID:Arg1.testID};
  const result = React.createElement(ActivityListBS.default, $props);
  return result
};

export default $$default;
