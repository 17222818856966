// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE

import * as Cn from "re-classnames/src/Cn.bs.js";
import * as React from "react";
import * as TestUtils from "../helpers/TestUtils.bs.js";
import * as DateHelper from "../helpers/DateHelper.bs.js";
import * as Caml_option from "bs-platform/lib/es6/caml_option.js";

require("./DelayedActorTimeDisplay.css");

function DelayedActorTimeDisplay(Props) {
  var testID = Props.testID;
  var className = Props.className;
  var style = Props.style;
  var actorName = Props.actorName;
  var time = Props.time;
  var className$1 = Cn.make(/* :: */[
        "hui_delayed_actor_time_display",
        /* :: */[
          Cn.unpack(className),
          /* [] */0
        ]
      ]);
  var match = process.browser;
  var formattedActorTime;
  if (actorName !== undefined) {
    var actorName$1 = actorName;
    if (time !== undefined) {
      if (match) {
        var time$1 = DateHelper.formatDate(time);
        formattedActorTime = "" + (String(actorName$1) + (", " + (String(time$1) + "")));
      } else {
        formattedActorTime = match ? null : actorName$1;
      }
    } else {
      formattedActorTime = match ? null : actorName$1;
    }
  } else {
    formattedActorTime = time !== undefined && match ? DateHelper.formatDate(time) : null;
  }
  var tmp = {
    className: className$1
  };
  if (style !== undefined) {
    tmp.style = Caml_option.valFromOption(style);
  }
  var div = React.createElement("div", tmp, formattedActorTime);
  return TestUtils.withTestID(Caml_option.some(testID), div);
}

var make = DelayedActorTimeDisplay;

var $$default = DelayedActorTimeDisplay;

export {
  make ,
  $$default ,
  $$default as default,
  
}
/*  Not a pure module */
