/* TypeScript file generated from Avatar.re by genType. */
/* eslint-disable import/first */


import * as React from 'react';

// tslint:disable-next-line:no-var-requires
const AvatarBS = require('./Avatar.bs');

import {style as ReactDOMRe_style} from '../../src/shims/ReactDOMRe.shim';

// tslint:disable-next-line:interface-over-type-literal
export type Props = {
  readonly backgroundColor?: string; 
  readonly className?: string; 
  readonly icon?: JSX.Element; 
  readonly style?: ReactDOMRe_style; 
  readonly testID?: string; 
  readonly url: string
};

export const $$default: React.ComponentType<{
  readonly backgroundColor?: string; 
  readonly className?: string; 
  readonly icon?: JSX.Element; 
  readonly style?: ReactDOMRe_style; 
  readonly testID?: string; 
  readonly url: string
}> = AvatarBS.default;

export default $$default;
