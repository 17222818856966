import Error from 'next/error';
import React from 'react';

export const NotFoundError: React.FC = () => (
  <Error title="This page could not be found" statusCode={404} />
);

export const UnexpectedError: React.FC = () => (
  <Error title="Internal Server Error" statusCode={500} />
);
