/* TypeScript file generated from HeroHeader.re by genType. */
/* eslint-disable import/first */


import * as React from 'react';

// tslint:disable-next-line:no-var-requires
const HeroHeaderBS = require('./HeroHeader.bs');

import {style as ReactDOMRe_style} from '../../src/shims/ReactDOMRe.shim';

// tslint:disable-next-line:interface-over-type-literal
export type Props = {
  readonly className?: string; 
  readonly imageUrl?: string; 
  readonly style?: ReactDOMRe_style; 
  readonly testID?: string; 
  readonly title: string
};

export const $$default: React.ComponentType<{
  readonly className?: string; 
  readonly imageUrl?: string; 
  readonly style?: ReactDOMRe_style; 
  readonly testID?: string; 
  readonly title: string
}> = HeroHeaderBS.default;

export default $$default;
