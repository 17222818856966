/* TypeScript file generated from DelayedActorTimeDisplay.re by genType. */
/* eslint-disable import/first */


import * as React from 'react';

// tslint:disable-next-line:no-var-requires
const DelayedActorTimeDisplayBS = require('./DelayedActorTimeDisplay.bs');

import {style as ReactDOMRe_style} from '../../src/shims/ReactDOMRe.shim';

// tslint:disable-next-line:interface-over-type-literal
export type Props = {
  readonly actorName?: string; 
  readonly className?: string; 
  readonly style?: ReactDOMRe_style; 
  readonly testID?: string; 
  readonly time?: string
};

export const $$default: React.ComponentType<{
  readonly actorName?: string; 
  readonly className?: string; 
  readonly style?: ReactDOMRe_style; 
  readonly testID?: string; 
  readonly time?: string
}> = DelayedActorTimeDisplayBS.default;

export default $$default;
