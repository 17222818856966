// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE


function ifNone(cn, x) {
  if (x !== undefined) {
    return "";
  } else {
    return cn;
  }
}

export {
  ifNone ,
  
}
/* No side effect */
