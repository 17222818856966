// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE

import * as Cn from "re-classnames/src/Cn.bs.js";
import * as React from "react";
import * as TestUtils from "../helpers/TestUtils.bs.js";
import * as Caml_option from "bs-platform/lib/es6/caml_option.js";

require("./Empty.css");

function Empty(Props) {
  var testID = Props.testID;
  var className = Props.className;
  var style = Props.style;
  var title = Props.title;
  var className$1 = Cn.make(/* :: */[
        "hui_empty",
        /* :: */[
          Cn.unpack(className),
          /* [] */0
        ]
      ]);
  var tmp = {
    className: className$1
  };
  if (style !== undefined) {
    tmp.style = Caml_option.valFromOption(style);
  }
  var div = React.createElement("div", tmp, React.createElement("div", {
            className: "body"
          }, title));
  return TestUtils.withTestID(Caml_option.some(testID), div);
}

var make = Empty;

var $$default = Empty;

export {
  make ,
  $$default ,
  $$default as default,
  
}
/*  Not a pure module */
