// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE

import * as Cn from "re-classnames/src/Cn.bs.js";
import * as React from "react";
import * as TestUtils from "../helpers/TestUtils.bs.js";
import * as Caml_option from "bs-platform/lib/es6/caml_option.js";

require("./ActivityIndicator.css");

function ActivityIndicator(Props) {
  var testID = Props.testID;
  var className = Props.className;
  var style = Props.style;
  var className$1 = Cn.make(/* :: */[
        "hui_activity_indicator",
        /* :: */[
          Cn.unpack(className),
          /* [] */0
        ]
      ]);
  var tmp = {
    className: className$1
  };
  if (style !== undefined) {
    tmp.style = Caml_option.valFromOption(style);
  }
  var div = React.createElement("div", tmp, React.createElement("svg", {
            className: "spinner",
            viewBox: "0 0 32 32"
          }, React.createElement("circle", {
                className: "path",
                cx: "16",
                cy: "16",
                fill: "none",
                r: "14",
                strokeWidth: "4"
              }), React.createElement("circle", {
                className: "dash",
                cx: "16",
                cy: "16",
                fill: "none",
                r: "14",
                strokeWidth: "4"
              })));
  return TestUtils.withTestID(Caml_option.some(testID), div);
}

var make = ActivityIndicator;

var $$default = ActivityIndicator;

export {
  make ,
  $$default ,
  $$default as default,
  
}
/*  Not a pure module */
