// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE

import * as Cn from "re-classnames/src/Cn.bs.js";
import * as Curry from "bs-platform/lib/es6/curry.js";
import * as Empty from "./Empty.bs.js";
import * as React from "react";
import * as TestID from "../helpers/TestID.bs.js";
import * as TestUtils from "../helpers/TestUtils.bs.js";
import * as Belt_Array from "bs-platform/lib/es6/belt_Array.js";
import * as Belt_Option from "bs-platform/lib/es6/belt_Option.js";
import * as Caml_option from "bs-platform/lib/es6/caml_option.js";
import * as NextStrings from "../helpers/NextStrings.bs.js";
import * as ContactCarouselItem from "./ContactCarouselItem.bs.js";

require("./ContactsCarousel.css");

function ContactsCarousel$Item(Props) {
  var testID = Props.testID;
  var className = Props.className;
  var style = Props.style;
  var item = Props.item;
  var onContactClick = Props.onContactClick;
  var match = item[/* interested */1];
  var match$1 = item[/* notInterested */2];
  var interest;
  var exit = 0;
  if (match !== undefined && match) {
    interest = /* Interested */0;
  } else {
    exit = 1;
  }
  if (exit === 1) {
    interest = match$1 !== undefined && match$1 ? /* NotInterested */1 : undefined;
  }
  var match$2 = item[/* contact */3];
  var match$3;
  if (match$2 !== undefined) {
    var match$4 = match$2;
    var match$5 = match$4[/* name */0];
    if (match$5 !== undefined) {
      var match$6 = match$4[/* imageUrl */1];
      match$3 = match$6 !== undefined ? /* tuple */[
          match$5,
          match$6
        ] : /* tuple */[
          "",
          ""
        ];
    } else {
      match$3 = /* tuple */[
        "",
        ""
      ];
    }
  } else {
    match$3 = /* tuple */[
      "",
      ""
    ];
  }
  var name = match$3[0];
  var onClick = function (param) {
    if (onContactClick !== undefined) {
      return Curry._1(onContactClick, item);
    } else {
      return /* () */0;
    }
  };
  var testID$1 = Belt_Option.getWithDefault(testID, "" + (String(TestID.spaceContactsGoToSpaceContact) + (":" + (String(name) + ""))));
  var tmp = {
    testID: testID$1,
    name: name,
    imageUrl: match$3[1],
    onClick: onClick
  };
  if (className !== undefined) {
    tmp.className = Caml_option.valFromOption(className);
  }
  if (style !== undefined) {
    tmp.style = Caml_option.valFromOption(style);
  }
  if (interest !== undefined) {
    tmp.interest = Caml_option.valFromOption(interest);
  }
  return React.createElement(ContactCarouselItem.make, tmp);
}

var Item = {
  make: ContactsCarousel$Item
};

function ContactsCarousel(Props) {
  var testID = Props.testID;
  var className = Props.className;
  var style = Props.style;
  var contacts = Props.contacts;
  var onContactClick = Props.onContactClick;
  var className$1 = Cn.make(/* :: */[
        "hui_contact_carousel",
        /* :: */[
          Cn.unpack(className),
          /* [] */0
        ]
      ]);
  var tmp = {
    className: className$1
  };
  if (style !== undefined) {
    tmp.style = Caml_option.valFromOption(style);
  }
  var div = React.createElement("div", tmp, contacts.length !== 0 ? React.createElement("div", {
              className: "grid_container"
            }, React.createElement("div", undefined), React.createElement("div", {
                  className: "grid"
                }, Belt_Array.map(contacts, (function (contact) {
                        var tmp = {
                          item: contact,
                          key: contact[/* id */0]
                        };
                        if (onContactClick !== undefined) {
                          tmp.onContactClick = Caml_option.valFromOption(onContactClick);
                        }
                        return React.createElement(ContactsCarousel$Item, tmp);
                      })))) : React.createElement(Empty.make, {
              testID: TestID.emptyPlaceholder,
              title: NextStrings.noContacts
            }));
  return TestUtils.withTestID(Caml_option.some(testID), div);
}

var make = ContactsCarousel;

var $$default = ContactsCarousel;

export {
  Item ,
  make ,
  $$default ,
  $$default as default,
  
}
/*  Not a pure module */
