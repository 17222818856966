// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE

import * as Cn from "re-classnames/src/Cn.bs.js";
import * as React from "react";
import * as TestUtils from "../helpers/TestUtils.bs.js";
import * as Caml_option from "bs-platform/lib/es6/caml_option.js";

require("./H2.css");

function H2(Props) {
  var testID = Props.testID;
  var className = Props.className;
  var style = Props.style;
  var children = Props.children;
  var className$1 = Cn.make(/* :: */[
        "hui_h2",
        /* :: */[
          Cn.unpack(className),
          /* [] */0
        ]
      ]);
  var tmp = {
    className: className$1
  };
  if (style !== undefined) {
    tmp.style = Caml_option.valFromOption(style);
  }
  var div = React.createElement("div", tmp, children);
  return TestUtils.withTestID(Caml_option.some(testID), div);
}

var make = H2;

var $$default = H2;

export {
  make ,
  $$default ,
  $$default as default,
  
}
/*  Not a pure module */
