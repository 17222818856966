/* TypeScript file generated from NoteAddedRow.re by genType. */
/* eslint-disable import/first */


import * as React from 'react';

// tslint:disable-next-line:no-var-requires
const NoteAddedRowBS = require('./NoteAddedRow.bs');

import {Mouse_t as ReactEvent_Mouse_t} from '../../src/shims/ReactEvent.shim';

import {NoteAddedActivity_t as Types_NoteAddedActivity_t} from '../../src/Types.gen';

import {style as ReactDOMRe_style} from '../../src/shims/ReactDOMRe.shim';

// tslint:disable-next-line:interface-over-type-literal
export type Props = {
  readonly activity: Types_NoteAddedActivity_t; 
  readonly children?: React.ReactNode; 
  readonly className?: string; 
  readonly contactPressed?: (_1:ReactEvent_Mouse_t) => void; 
  readonly hideSpace?: boolean; 
  readonly spacePressed?: (_1:ReactEvent_Mouse_t) => void; 
  readonly style?: ReactDOMRe_style; 
  readonly testID?: string
};

export const $$default: React.ComponentType<{
  readonly activity: Types_NoteAddedActivity_t; 
  readonly children?: React.ReactNode; 
  readonly className?: string; 
  readonly contactPressed?: (_1:ReactEvent_Mouse_t) => void; 
  readonly hideSpace?: boolean; 
  readonly spacePressed?: (_1:ReactEvent_Mouse_t) => void; 
  readonly style?: ReactDOMRe_style; 
  readonly testID?: string
}> = function NoteAddedRow(Arg1: any) {
  const $props = {activity:[Arg1.activity.id, (Arg1.activity.target == null ? undefined : [(Arg1.activity.target.contact == null ? undefined : [Arg1.activity.target.contact.name, Arg1.activity.target.contact.imageUrl])]), (Arg1.activity.space == null ? undefined : [Arg1.activity.space.title]), (Arg1.activity.actor == null ? undefined : [Arg1.activity.actor.firstName]), (Arg1.activity.object == null ? undefined : [Arg1.activity.object.text, Arg1.activity.object.isVendorComment]), Arg1.activity.time, Arg1.activity.verbEnum], children:Arg1.children, className:Arg1.className, contactPressed:Arg1.contactPressed, hideSpace:Arg1.hideSpace, spacePressed:Arg1.spacePressed, style:Arg1.style, testID:Arg1.testID};
  const result = React.createElement(NoteAddedRowBS.default, $props);
  return result
};

export default $$default;
