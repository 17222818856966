/* TypeScript file generated from BackIcon.re by genType. */
/* eslint-disable import/first */


import * as React from 'react';

// tslint:disable-next-line:no-var-requires
const BackIconBS = require('./BackIcon.bs');

import {Mouse_t as ReactEvent_Mouse_t} from '../../src/shims/ReactEvent.shim';

import {style as ReactDOMRe_style} from '../../src/shims/ReactDOMRe.shim';

// tslint:disable-next-line:interface-over-type-literal
export type Props = {
  readonly className?: string; 
  readonly onClick?: (_1:ReactEvent_Mouse_t) => void; 
  readonly style?: ReactDOMRe_style; 
  readonly testID?: string
};

export const $$default: React.ComponentType<{
  readonly className?: string; 
  readonly onClick?: (_1:ReactEvent_Mouse_t) => void; 
  readonly style?: ReactDOMRe_style; 
  readonly testID?: string
}> = BackIconBS.default;

export default $$default;
