/* TypeScript file generated from Pill.re by genType. */
/* eslint-disable import/first */


import * as React from 'react';

// tslint:disable-next-line:no-var-requires
const PillBS = require('./Pill.bs');

import {style as ReactDOMRe_style} from '../../src/shims/ReactDOMRe.shim';

// tslint:disable-next-line:interface-over-type-literal
export type Props = {
  readonly className?: string; 
  readonly label?: string; 
  readonly style?: ReactDOMRe_style; 
  readonly testID?: string; 
  readonly value?: string
};

export const $$default: React.ComponentType<{
  readonly className?: string; 
  readonly label?: string; 
  readonly style?: ReactDOMRe_style; 
  readonly testID?: string; 
  readonly value?: string
}> = PillBS.default;

export default $$default;
