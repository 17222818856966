// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE

import * as Cn from "re-classnames/src/Cn.bs.js";
import * as H2 from "./H2.bs.js";
import * as $$Image from "./Image.bs.js";
import * as React from "react";
import * as TestUtils from "../helpers/TestUtils.bs.js";
import * as Caml_option from "bs-platform/lib/es6/caml_option.js";

require("./HeroHeader.css");

function HeroHeader(Props) {
  var testID = Props.testID;
  var className = Props.className;
  var style = Props.style;
  var title = Props.title;
  var imageUrl = Props.imageUrl;
  var className$1 = Cn.make(/* :: */[
        "hui_hero_header",
        /* :: */[
          Cn.unpack(className),
          /* [] */0
        ]
      ]);
  var tmp = {
    className: className$1
  };
  if (style !== undefined) {
    tmp.style = Caml_option.valFromOption(style);
  }
  var tmp$1 = {
    className: "image",
    alt: "hero image"
  };
  if (imageUrl !== undefined) {
    tmp$1.src = Caml_option.valFromOption(imageUrl);
  }
  var div = React.createElement("div", tmp, React.createElement($$Image.make, tmp$1), React.createElement("div", {
            className: "overlay"
          }), React.createElement("div", {
            className: "text_overlay"
          }, React.createElement("div", {
                className: "main"
              }, React.createElement(H2.make, {
                    className: "h2",
                    children: title
                  }))));
  return TestUtils.withTestID(Caml_option.some(testID), div);
}

var make = HeroHeader;

var $$default = HeroHeader;

export {
  make ,
  $$default ,
  $$default as default,
  
}
/*  Not a pure module */
