/* TypeScript file generated from PageLayout.re by genType. */
/* eslint-disable import/first */


import * as React from 'react';

// tslint:disable-next-line:no-var-requires
const PageLayoutBS = require('./PageLayout.bs');

import {style as ReactDOMRe_style} from '../../src/shims/ReactDOMRe.shim';

// tslint:disable-next-line:interface-over-type-literal
export type navigationProps = {
  readonly headerLeft?: JSX.Element; 
  readonly headerTitle?: JSX.Element; 
  readonly headerRight?: JSX.Element; 
  readonly fixed?: boolean
};

// tslint:disable-next-line:interface-over-type-literal
export type Props = {
  readonly children: React.ReactNode; 
  readonly className?: string; 
  readonly headerComponent?: JSX.Element; 
  readonly navBarVisible?: boolean; 
  readonly navigationProps?: navigationProps; 
  readonly style?: ReactDOMRe_style; 
  readonly subscribeToMore?: (_1:void) => void; 
  readonly testID?: string; 
  readonly title?: string
};

export const $$default: React.ComponentType<{
  readonly children: React.ReactNode; 
  readonly className?: string; 
  readonly headerComponent?: JSX.Element; 
  readonly navBarVisible?: boolean; 
  readonly navigationProps?: navigationProps; 
  readonly style?: ReactDOMRe_style; 
  readonly subscribeToMore?: (_1:void) => void; 
  readonly testID?: string; 
  readonly title?: string
}> = function PageLayout(Arg1: any) {
  const $props = {children:Arg1.children, className:Arg1.className, headerComponent:Arg1.headerComponent, navBarVisible:Arg1.navBarVisible, navigationProps:(Arg1.navigationProps == null ? undefined : [Arg1.navigationProps.headerLeft, Arg1.navigationProps.headerTitle, Arg1.navigationProps.headerRight, Arg1.navigationProps.fixed]), style:Arg1.style, subscribeToMore:Arg1.subscribeToMore, testID:Arg1.testID, title:Arg1.title};
  const result = React.createElement(PageLayoutBS.default, $props);
  return result
};

export default $$default;
