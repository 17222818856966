// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE

import * as Cn from "re-classnames/src/Cn.bs.js";
import * as Theme from "../Theme.bs.js";
import * as React from "react";
import * as Touchable from "./Touchable.bs.js";
import * as Caml_option from "bs-platform/lib/es6/caml_option.js";
import * as AvatarWithInterest from "./AvatarWithInterest.bs.js";

require("./ContactCarouselItem.css");

function ContactCarouselItem(Props) {
  var testID = Props.testID;
  var className = Props.className;
  var style = Props.style;
  var interest = Props.interest;
  var name = Props.name;
  var imageUrl = Props.imageUrl;
  var onClick = Props.onClick;
  var className$1 = Cn.make(/* :: */[
        "hui_contact_carousel_item",
        /* :: */[
          Cn.unpack(className),
          /* [] */0
        ]
      ]);
  var tmp = {
    className: className$1,
    children: null
  };
  if (testID !== undefined) {
    tmp.testID = Caml_option.valFromOption(testID);
  }
  if (style !== undefined) {
    tmp.style = Caml_option.valFromOption(style);
  }
  if (onClick !== undefined) {
    tmp.onClick = Caml_option.valFromOption(onClick);
  }
  var tmp$1 = {
    className: "avatar",
    url: imageUrl,
    backgroundColor: Theme.colors[/* gray */13]
  };
  if (interest !== undefined) {
    tmp$1.interest = Caml_option.valFromOption(interest);
  }
  return React.createElement(Touchable.make, tmp, React.createElement(AvatarWithInterest.make, tmp$1), React.createElement("div", {
                  className: "text"
                }, name));
}

var make = ContactCarouselItem;

var $$default = ContactCarouselItem;

export {
  make ,
  $$default ,
  $$default as default,
  
}
/*  Not a pure module */
