// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE

import * as Cn from "re-classnames/src/Cn.bs.js";
import * as Icon from "./Icon.bs.js";
import * as React from "react";
import * as TestUtils from "../helpers/TestUtils.bs.js";
import * as ReactDOMRe from "reason-react/src/ReactDOMRe.js";
import * as Belt_Option from "bs-platform/lib/es6/belt_Option.js";
import * as Caml_option from "bs-platform/lib/es6/caml_option.js";

require("./CircleIcon.css");

function CircleIcon(Props) {
  var testID = Props.testID;
  var className = Props.className;
  var style = Props.style;
  var name = Props.name;
  var color = Props.color;
  var match = Props.iconSize;
  var iconSize = match !== undefined ? match : 12;
  var iconColor = Props.iconColor;
  var match$1 = Props.thumbSize;
  var thumbSize = match$1 !== undefined ? match$1 : 20;
  var className$1 = Cn.make(/* :: */[
        "hui_circle_icon",
        /* :: */[
          Cn.unpack(className),
          /* [] */0
        ]
      ]);
  var colorStyle = color !== undefined ? ({
        backgroundColor: color
      }) : { };
  var thumbSize$1 = String(thumbSize) + "px";
  var sizeStyle = {
    height: thumbSize$1,
    width: thumbSize$1
  };
  var providedStyle = Belt_Option.getWithDefault(style, { });
  var style$1 = ReactDOMRe.Style.combine(ReactDOMRe.Style.combine(colorStyle, sizeStyle), providedStyle);
  var tmp = {
    name: name,
    light: true,
    size: iconSize
  };
  if (iconColor !== undefined) {
    tmp.color = Caml_option.valFromOption(iconColor);
  }
  var div = React.createElement("div", {
        className: className$1,
        style: style$1
      }, React.createElement(Icon.make, tmp));
  return TestUtils.withTestID(Caml_option.some(testID), div);
}

var make = CircleIcon;

var $$default = CircleIcon;

export {
  make ,
  $$default ,
  $$default as default,
  
}
/*  Not a pure module */
