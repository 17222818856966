// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE

import * as Cn from "re-classnames/src/Cn.bs.js";
import * as React from "react";
import * as TestUtils from "../helpers/TestUtils.bs.js";
import * as Belt_Option from "bs-platform/lib/es6/belt_Option.js";
import * as Caml_option from "bs-platform/lib/es6/caml_option.js";

require("./ListItem.css");

function ListItem(Props) {
  var testID = Props.testID;
  var className = Props.className;
  var style = Props.style;
  var bottomBorderType = Props.bottomBorderType;
  var left = Props.left;
  var body = Props.body;
  var right = Props.right;
  var children = Props.children;
  var match;
  if (bottomBorderType !== undefined) {
    var match$1 = bottomBorderType;
    match = match$1 !== -1010505329 ? (
        match$1 !== -754980010 ? /* tuple */[
            false,
            false
          ] : /* tuple */[
            false,
            true
          ]
      ) : /* tuple */[
        true,
        false
      ];
  } else {
    match = /* tuple */[
      false,
      false
    ];
  }
  var addBottomBorderWithLeftInset = match[1];
  var left$1 = left !== undefined ? React.createElement("div", {
          className: "left"
        }, Caml_option.valFromOption(left)) : null;
  var bodyClassName = Cn.make(/* :: */[
        "body",
        /* :: */[
          Cn.ifTrue("hui_list_item_bordered", addBottomBorderWithLeftInset),
          /* [] */0
        ]
      ]);
  var body$1 = React.createElement("div", {
        className: bodyClassName
      }, Belt_Option.getWithDefault(body, null));
  var rightClassName = Cn.make(/* :: */[
        "right",
        /* :: */[
          Cn.ifTrue("hui_list_item_bordered", addBottomBorderWithLeftInset),
          /* [] */0
        ]
      ]);
  var right$1 = right !== undefined ? React.createElement("div", {
          className: rightClassName
        }, Caml_option.valFromOption(right)) : null;
  var className$1 = Cn.make(/* :: */[
        "hui_list_item",
        /* :: */[
          Cn.ifTrue("hui_list_item_bordered", match[0]),
          /* :: */[
            Cn.unpack(className),
            /* [] */0
          ]
        ]
      ]);
  var tmp = {
    className: className$1
  };
  if (style !== undefined) {
    tmp.style = Caml_option.valFromOption(style);
  }
  var div = React.createElement("div", tmp, left$1, body$1, right$1, Belt_Option.getWithDefault(children, null));
  return TestUtils.withTestID(Caml_option.some(testID), div);
}

var make = ListItem;

var $$default = ListItem;

export {
  make ,
  $$default ,
  $$default as default,
  
}
/*  Not a pure module */
