// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE

import * as Cn from "re-classnames/src/Cn.bs.js";
import * as H2 from "./H2.bs.js";
import * as Theme from "../Theme.bs.js";
import * as React from "react";
import * as Divider from "./Divider.bs.js";
import * as TestUtils from "../helpers/TestUtils.bs.js";
import * as Caml_option from "bs-platform/lib/es6/caml_option.js";
import * as NextStrings from "../helpers/NextStrings.bs.js";
import * as ThumbWithInterest from "./ThumbWithInterest.bs.js";
import * as ReactSvgDonutChart from "react-svg-donut-chart";

require("./StatsView.css");

function StatsView(Props) {
  var testID = Props.testID;
  var className = Props.className;
  var style = Props.style;
  var spaceContactCount = Props.spaceContactCount;
  var match = Props.interestedSpaceContactCount;
  var interestedSpaceContactCount = match !== undefined ? match : 0;
  var match$1 = Props.notInterestedSpaceContactCount;
  var notInterestedSpaceContactCount = match$1 !== undefined ? match$1 : 0;
  if (spaceContactCount !== undefined) {
    var spaceContactCount$1 = spaceContactCount;
    var data = /* array */[
      {
        value: spaceContactCount$1 - (interestedSpaceContactCount + notInterestedSpaceContactCount | 0) | 0,
        stroke: Theme.colors[/* lightGray */10],
        strokeWidth: 3
      },
      {
        value: interestedSpaceContactCount,
        stroke: Theme.colors[/* green */12]
      },
      {
        value: notInterestedSpaceContactCount,
        stroke: Theme.colors[/* alert */4]
      }
    ];
    var className$1 = Cn.make(/* :: */[
          "hui_stats_view",
          /* :: */[
            Cn.unpack(className),
            /* [] */0
          ]
        ]);
    var tmp = {
      className: className$1
    };
    if (style !== undefined) {
      tmp.style = Caml_option.valFromOption(style);
    }
    var div = React.createElement("div", tmp, React.createElement("div", {
              className: "chart"
            }, React.createElement(ReactSvgDonutChart.default, {
                  data: data,
                  spacing: 1
                }), React.createElement("div", {
                  className: "chart_middle"
                }, React.createElement(H2.make, {
                      children: String(spaceContactCount$1)
                    }), React.createElement("div", undefined, NextStrings.people))), React.createElement("div", {
              className: "right"
            }, React.createElement("div", {
                  className: "legend_row"
                }, React.createElement(ThumbWithInterest.make, {
                      className: "thumb",
                      interest: /* Interested */0,
                      thumbIconSize: 18,
                      thumbSize: 30
                    }), React.createElement("div", {
                      className: "legend"
                    }, NextStrings.interested), React.createElement("div", {
                      className: "count"
                    }, String(interestedSpaceContactCount))), React.createElement(Divider.make, { }), React.createElement("div", {
                  className: "legend_row"
                }, React.createElement(ThumbWithInterest.make, {
                      className: "thumb",
                      interest: /* NotInterested */1,
                      thumbIconSize: 18,
                      thumbSize: 30
                    }), React.createElement("div", {
                      className: "legend"
                    }, NextStrings.notInterested), React.createElement("div", {
                      className: "count"
                    }, String(notInterestedSpaceContactCount)))));
    return TestUtils.withTestID(Caml_option.some(testID), div);
  } else {
    return null;
  }
}

var make = StatsView;

var $$default = StatsView;

export {
  make ,
  $$default ,
  $$default as default,
  
}
/*  Not a pure module */
