/* TypeScript file generated from ListItem.re by genType. */
/* eslint-disable import/first */


import * as React from 'react';

const $$toRE888505223: { [key: string]: any } = {"full": -1010505329, "leftInset": -754980010};

// tslint:disable-next-line:no-var-requires
const ListItemBS = require('./ListItem.bs');

import {style as ReactDOMRe_style} from '../../src/shims/ReactDOMRe.shim';

// tslint:disable-next-line:interface-over-type-literal
export type bottomBorderType = "none" | "leftInset" | "full";

// tslint:disable-next-line:interface-over-type-literal
export type Props = {
  readonly body?: JSX.Element; 
  readonly bottomBorderType?: 
    "full"
  | "leftInset"; 
  readonly children?: React.ReactNode; 
  readonly className?: string; 
  readonly left?: JSX.Element; 
  readonly right?: JSX.Element; 
  readonly style?: ReactDOMRe_style; 
  readonly testID?: string
};

export const $$default: React.ComponentType<{
  readonly body?: JSX.Element; 
  readonly bottomBorderType?: 
    "full"
  | "leftInset"; 
  readonly children?: React.ReactNode; 
  readonly className?: string; 
  readonly left?: JSX.Element; 
  readonly right?: JSX.Element; 
  readonly style?: ReactDOMRe_style; 
  readonly testID?: string
}> = function ListItem(Arg1: any) {
  const $props = {body:Arg1.body, bottomBorderType:(Arg1.bottomBorderType == null ? undefined : $$toRE888505223[Arg1.bottomBorderType]), children:Arg1.children, className:Arg1.className, left:Arg1.left, right:Arg1.right, style:Arg1.style, testID:Arg1.testID};
  const result = React.createElement(ListItemBS.default, $props);
  return result
};

export default $$default;
