// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE

import * as Cn from "re-classnames/src/Cn.bs.js";
import * as H2 from "./H2.bs.js";
import * as Pill from "./Pill.bs.js";
import * as Theme from "../Theme.bs.js";
import * as React from "react";
import * as TestUtils from "../helpers/TestUtils.bs.js";
import * as Caml_option from "bs-platform/lib/es6/caml_option.js";
import * as NextStrings from "../helpers/NextStrings.bs.js";
import * as AvatarWithInterest from "./AvatarWithInterest.bs.js";

require("./ContactHeader.css");

function ContactHeader(Props) {
  var testID = Props.testID;
  var className = Props.className;
  var style = Props.style;
  var interest = Props.interest;
  var name = Props.name;
  var imageUrl = Props.imageUrl;
  var visitCount = Props.visitCount;
  var hasSentDocs = Props.hasSentDocs;
  var className$1 = Cn.make(/* :: */[
        "hui_contact_header",
        /* :: */[
          Cn.unpack(className),
          /* [] */0
        ]
      ]);
  var tmp = {
    className: className$1
  };
  if (style !== undefined) {
    tmp.style = Caml_option.valFromOption(style);
  }
  var tmp$1 = {
    className: "avatar",
    thumbSize: 34,
    thumbIconSize: 22,
    url: imageUrl,
    backgroundColor: Theme.colors[/* gray */13]
  };
  if (interest !== undefined) {
    tmp$1.interest = Caml_option.valFromOption(interest);
  }
  var tmp$2;
  if (visitCount !== undefined) {
    var visitCount$1 = visitCount;
    tmp$2 = visitCount$1 > 0 ? React.createElement(Pill.make, {
            className: "pill",
            label: NextStrings.visits,
            value: String(visitCount$1)
          }) : null;
  } else {
    tmp$2 = null;
  }
  var div = React.createElement("div", tmp, React.createElement(AvatarWithInterest.make, tmp$1), React.createElement(H2.make, {
            className: "h2",
            children: name
          }), React.createElement("div", {
            className: "row"
          }, tmp$2, hasSentDocs !== undefined && hasSentDocs ? React.createElement(Pill.make, {
                  className: "pill",
                  label: NextStrings.docsSent
                }) : null));
  return TestUtils.withTestID(Caml_option.some(testID), div);
}

var make = ContactHeader;

var $$default = ContactHeader;

export {
  make ,
  $$default ,
  $$default as default,
  
}
/*  Not a pure module */
