// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE

import * as Cn from "re-classnames/src/Cn.bs.js";
import * as Curry from "bs-platform/lib/es6/curry.js";
import * as React from "react";
import * as CnUtils from "../helpers/CnUtils.bs.js";
import * as TestUtils from "../helpers/TestUtils.bs.js";
import * as Caml_option from "bs-platform/lib/es6/caml_option.js";
import * as ReactEventUtils from "../helpers/ReactEventUtils.bs.js";

require("./Touchable.css");

function Touchable(Props) {
  var testID = Props.testID;
  var className = Props.className;
  var style = Props.style;
  var onClick = Props.onClick;
  var children = Props.children;
  var className$1 = Cn.make(/* :: */[
        "hui_touchable",
        /* :: */[
          CnUtils.ifNone("hui_touchable_disabled", onClick),
          /* :: */[
            Cn.unpack(className),
            /* [] */0
          ]
        ]
      ]);
  var handleClick = function ($$event) {
    $$event.preventDefault();
    if (onClick !== undefined) {
      return Curry._1(onClick, $$event);
    } else {
      return /* () */0;
    }
  };
  var tmp = {
    className: className$1,
    role: "button",
    onKeyDown: ReactEventUtils.noop,
    onClick: handleClick,
    onTouchStart: ReactEventUtils.noop
  };
  if (style !== undefined) {
    tmp.style = Caml_option.valFromOption(style);
  }
  var a = React.createElement("a", tmp, children !== undefined ? Caml_option.valFromOption(children) : null);
  return TestUtils.withTestID(Caml_option.some(testID), a);
}

var make = Touchable;

var $$default = Touchable;

export {
  make ,
  $$default ,
  $$default as default,
  
}
/*  Not a pure module */
