// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE


var fake = "fake-test-id";

var goBack = "ui-goBack";

var title = "ui-title";

var spaceTitle = "space-title";

var spaceGoToActivities = "space-goToActivities";

var spaceGoToContacts = "space-goToContacts";

var spaceContactsGoToSpaceContact = "spaceContacts-carousel-goTo";

var spaceActivitiesGoToSpaceContact = "spaceActivities-list-goTo";

var seeAll = "see-all";

var alertDot = "alert-dot";

var emptyPlaceholder = "empty-placeholder";

var uiActivityIndicator = "ui-activity-indicator";

var uiActivityList = "ui-activity-list";

var uiAlertDot = "ui-alert-dot";

var uiAvatar = "ui-avatar";

var uiAvatarWithInterest = "ui-avatar-with-interest";

var uiBackIcon = "ui-back-icon";

var uiCheckinRow = "ui-checkin-row";

var uiCircleIcon = "ui-circle-icon";

var uiContactCarouselItem = "ui-contact-carousel-item";

var uiContactHeader = "ui-contact-header";

var uiContactsCarousel = "ui-contacts-carousel";

var uiContactsList = "ui-contacts-list";

var uiDelayedActorTimeDisplay = "ui-delayed-actor-time-display";

var uiDivider = "ui-divider";

var uiDocsSentRow = "ui-docs-SentRow";

var uiEmpty = "ui-empty";

var uiH1 = "ui-h1";

var uiH2 = "ui-h2";

var uiHeroHeader = "ui-hero-header";

var uiHomepassLogoFullscreen = "ui-homepass-logo-fullscreen";

var uiIcon = "ui-icon";

var uiListItem = "ui-list-item";

var uiNavBar = "ui-nav-bar";

var uiNavigationTitle = "ui-navigation-title";

var uiNoteAddedRow = "ui-note-added-row";

var uiPageLayout = "ui-page-layout";

var uiPill = "ui-pill";

var uiSectionHeader = "ui-section-header";

var uiStatsView = "ui-stats-view";

var uiThumbWithInterestInterested = "ui-thumb-with-interest-interested";

var uiThumbWithInterestNotInterested = "ui-thumb-with-interest-not-interested";

var uiTouchable = "ui-touchable";

export {
  fake ,
  goBack ,
  title ,
  spaceTitle ,
  spaceGoToActivities ,
  spaceGoToContacts ,
  spaceContactsGoToSpaceContact ,
  spaceActivitiesGoToSpaceContact ,
  seeAll ,
  alertDot ,
  emptyPlaceholder ,
  uiActivityIndicator ,
  uiActivityList ,
  uiAlertDot ,
  uiAvatar ,
  uiAvatarWithInterest ,
  uiBackIcon ,
  uiCheckinRow ,
  uiCircleIcon ,
  uiContactCarouselItem ,
  uiContactHeader ,
  uiContactsCarousel ,
  uiContactsList ,
  uiDelayedActorTimeDisplay ,
  uiDivider ,
  uiDocsSentRow ,
  uiEmpty ,
  uiH1 ,
  uiH2 ,
  uiHeroHeader ,
  uiHomepassLogoFullscreen ,
  uiIcon ,
  uiListItem ,
  uiNavBar ,
  uiNavigationTitle ,
  uiNoteAddedRow ,
  uiPageLayout ,
  uiPill ,
  uiSectionHeader ,
  uiStatsView ,
  uiThumbWithInterestInterested ,
  uiThumbWithInterestNotInterested ,
  uiTouchable ,
  
}
/* No side effect */
