// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE

import * as Curry from "bs-platform/lib/es6/curry.js";
import * as React from "react";
import * as TestUtils from "../helpers/TestUtils.bs.js";
import * as Caml_option from "bs-platform/lib/es6/caml_option.js";

function $$Image(Props) {
  var testID = Props.testID;
  var className = Props.className;
  var style = Props.style;
  var src = Props.src;
  var alt = Props.alt;
  var match = React.useState((function () {
          return false;
        }));
  var setErrored = match[1];
  if (match[0] || src === undefined) {
    return null;
  } else {
    var onError = function (param) {
      return Curry._1(setErrored, (function (param) {
                    return true;
                  }));
    };
    var tmp = {
      alt: alt,
      src: src,
      onError: onError
    };
    if (className !== undefined) {
      tmp.className = Caml_option.valFromOption(className);
    }
    if (style !== undefined) {
      tmp.style = Caml_option.valFromOption(style);
    }
    var img = React.createElement("img", tmp);
    return TestUtils.withTestID(Caml_option.some(testID), img);
  }
}

var make = $$Image;

var $$default = $$Image;

export {
  make ,
  $$default ,
  $$default as default,
  
}
/* react Not a pure module */
