// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE

import * as Theme from "../Theme.bs.js";
import * as React from "react";
import * as CircleIcon from "./CircleIcon.bs.js";
import * as Caml_option from "bs-platform/lib/es6/caml_option.js";

function ThumbWithInterest(Props) {
  var testID = Props.testID;
  var className = Props.className;
  var style = Props.style;
  var interest = Props.interest;
  var thumbIconSize = Props.thumbIconSize;
  var thumbSize = Props.thumbSize;
  if (interest !== undefined) {
    if (interest) {
      var tmp = {
        name: /* ThumbsDown */914943167,
        color: Theme.colors[/* alert */4]
      };
      if (testID !== undefined) {
        tmp.testID = Caml_option.valFromOption(testID);
      }
      if (className !== undefined) {
        tmp.className = Caml_option.valFromOption(className);
      }
      if (style !== undefined) {
        tmp.style = Caml_option.valFromOption(style);
      }
      if (thumbIconSize !== undefined) {
        tmp.iconSize = Caml_option.valFromOption(thumbIconSize);
      }
      if (thumbSize !== undefined) {
        tmp.thumbSize = Caml_option.valFromOption(thumbSize);
      }
      return React.createElement(CircleIcon.make, tmp);
    } else {
      var tmp$1 = {
        name: /* ThumbsUp */-187524744,
        color: Theme.colors[/* green */12]
      };
      if (testID !== undefined) {
        tmp$1.testID = Caml_option.valFromOption(testID);
      }
      if (className !== undefined) {
        tmp$1.className = Caml_option.valFromOption(className);
      }
      if (style !== undefined) {
        tmp$1.style = Caml_option.valFromOption(style);
      }
      if (thumbIconSize !== undefined) {
        tmp$1.iconSize = Caml_option.valFromOption(thumbIconSize);
      }
      if (thumbSize !== undefined) {
        tmp$1.thumbSize = Caml_option.valFromOption(thumbSize);
      }
      return React.createElement(CircleIcon.make, tmp$1);
    }
  } else {
    return null;
  }
}

var make = ThumbWithInterest;

var $$default = ThumbWithInterest;

export {
  make ,
  $$default ,
  $$default as default,
  
}
/* react Not a pure module */
