// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE

import * as Cn from "re-classnames/src/Cn.bs.js";
import * as Empty from "./Empty.bs.js";
import * as React from "react";
import * as TestID from "../helpers/TestID.bs.js";
import * as TestUtils from "../helpers/TestUtils.bs.js";
import * as Belt_Array from "bs-platform/lib/es6/belt_Array.js";
import * as CheckinRow from "./CheckinRow.bs.js";
import * as Caml_option from "bs-platform/lib/es6/caml_option.js";
import * as DocsSentRow from "./DocsSentRow.bs.js";
import * as NextStrings from "../helpers/NextStrings.bs.js";
import * as NoteAddedRow from "./NoteAddedRow.bs.js";
import * as ReactEventUtils from "../helpers/ReactEventUtils.bs.js";
import * as ActivityIndicator from "./ActivityIndicator.bs.js";
import * as ReactInfiniteScroller from "react-infinite-scroller";

require("./ActivityList.css");

function ActivityList$Item(Props) {
  var testID = Props.testID;
  var className = Props.className;
  var style = Props.style;
  var item = Props.item;
  var className$1 = Cn.make(/* :: */[
        "hui_activity_list_item",
        /* :: */[
          Cn.unpack(className),
          /* [] */0
        ]
      ]);
  var match = item[/* activity */1];
  var variant = match[0];
  if (variant !== 618569084) {
    if (variant >= 1039969026) {
      var tmp = {
        className: className$1,
        activity: match[1]
      };
      if (testID !== undefined) {
        tmp.testID = Caml_option.valFromOption(testID);
      }
      if (style !== undefined) {
        tmp.style = Caml_option.valFromOption(style);
      }
      var tmp$1 = item[/* contactPressed */3];
      if (tmp$1 !== undefined) {
        tmp.contactPressed = Caml_option.valFromOption(tmp$1);
      }
      var tmp$2 = item[/* hideSpace */2];
      if (tmp$2 !== undefined) {
        tmp.hideSpace = Caml_option.valFromOption(tmp$2);
      }
      return React.createElement(DocsSentRow.make, tmp);
    } else {
      var tmp$3 = {
        className: className$1,
        activity: match[1]
      };
      if (testID !== undefined) {
        tmp$3.testID = Caml_option.valFromOption(testID);
      }
      if (style !== undefined) {
        tmp$3.style = Caml_option.valFromOption(style);
      }
      var tmp$4 = item[/* contactPressed */3];
      if (tmp$4 !== undefined) {
        tmp$3.contactPressed = Caml_option.valFromOption(tmp$4);
      }
      var tmp$5 = item[/* hideSpace */2];
      if (tmp$5 !== undefined) {
        tmp$3.hideSpace = Caml_option.valFromOption(tmp$5);
      }
      return React.createElement(NoteAddedRow.make, tmp$3);
    }
  } else {
    var tmp$6 = {
      className: className$1,
      activity: match[1]
    };
    if (testID !== undefined) {
      tmp$6.testID = Caml_option.valFromOption(testID);
    }
    if (style !== undefined) {
      tmp$6.style = Caml_option.valFromOption(style);
    }
    var tmp$7 = item[/* contactPressed */3];
    if (tmp$7 !== undefined) {
      tmp$6.contactPressed = Caml_option.valFromOption(tmp$7);
    }
    var tmp$8 = item[/* hideSpace */2];
    if (tmp$8 !== undefined) {
      tmp$6.hideSpace = Caml_option.valFromOption(tmp$8);
    }
    return React.createElement(CheckinRow.make, tmp$6);
  }
}

var Item = {
  make: ActivityList$Item
};

function ActivityList(Props) {
  var testID = Props.testID;
  var className = Props.className;
  var style = Props.style;
  var match = Props.onEndReached;
  var onEndReached = match !== undefined ? match : ReactEventUtils.noop;
  var match$1 = Props.hasMore;
  var hasMore = match$1 !== undefined ? match$1 : false;
  var activities = Props.activities;
  var loader = React.createElement(ActivityIndicator.make, {
        className: "hui_activity_list_indicator",
        key: "loading"
      });
  var tmp = {
    loadMore: onEndReached,
    hasMore: hasMore,
    loader: loader,
    children: React.createElement("div", {
          key: "activity-list-container"
        }, activities.length !== 0 ? Belt_Array.map(activities, (function (activity) {
                  return React.createElement(ActivityList$Item, {
                              item: activity,
                              key: activity[/* id */0]
                            });
                })) : React.createElement(Empty.make, {
                testID: TestID.emptyPlaceholder,
                title: NextStrings.noActivities
              }))
  };
  if (className !== undefined) {
    tmp.className = Caml_option.valFromOption(className);
  }
  if (style !== undefined) {
    tmp.style = Caml_option.valFromOption(style);
  }
  var scroll = React.createElement(ReactInfiniteScroller.default, tmp);
  return TestUtils.withTestID(Caml_option.some(testID), scroll);
}

var make = ActivityList;

var $$default = ActivityList;

export {
  Item ,
  make ,
  $$default ,
  $$default as default,
  
}
/*  Not a pure module */
