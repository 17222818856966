/* TypeScript file generated from Icon.re by genType. */
/* eslint-disable import/first */


import * as React from 'react';

const $$toRE438741426: { [key: string]: any } = {"ThumbsUp": -187524744, "ThumbsDown": 914943167, "Pencil": -318347283, "Attachment": -735835133, "Document": -188437701, "ArrowForwardRound": -668824686, "ArrowBack": 512194672};

// tslint:disable-next-line:no-var-requires
const IconBS = require('./Icon.bs');

import {style as ReactDOMRe_style} from '../../src/shims/ReactDOMRe.shim';

// tslint:disable-next-line:interface-over-type-literal
export type icon = 
    "ThumbsUp"
  | "ThumbsDown"
  | "Pencil"
  | "Attachment"
  | "Document"
  | "ArrowForwardRound"
  | "ArrowBack";

// tslint:disable-next-line:interface-over-type-literal
export type Props = {
  readonly className?: string; 
  readonly color?: string; 
  readonly light?: boolean; 
  readonly name: icon; 
  readonly size?: number; 
  readonly style?: ReactDOMRe_style; 
  readonly testID?: string
};

export const $$default: React.ComponentType<{
  readonly className?: string; 
  readonly color?: string; 
  readonly light?: boolean; 
  readonly name: icon; 
  readonly size?: number; 
  readonly style?: ReactDOMRe_style; 
  readonly testID?: string
}> = function Icon(Arg1: any) {
  const $props = {className:Arg1.className, color:Arg1.color, light:Arg1.light, name:$$toRE438741426[Arg1.name], size:Arg1.size, style:Arg1.style, testID:Arg1.testID};
  const result = React.createElement(IconBS.default, $props);
  return result
};

export default $$default;
