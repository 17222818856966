// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE

import * as Cn from "re-classnames/src/Cn.bs.js";
import * as Curry from "bs-platform/lib/es6/curry.js";
import * as React from "react";
import * as NavBar from "./NavBar.bs.js";
import * as TestID from "../helpers/TestID.bs.js";
import * as TestUtils from "../helpers/TestUtils.bs.js";
import * as Belt_Option from "bs-platform/lib/es6/belt_Option.js";
import * as Caml_option from "bs-platform/lib/es6/caml_option.js";
import * as NavigationTitle from "./NavigationTitle.bs.js";

require("./PageLayout.css");

function PageLayout(Props) {
  var testID = Props.testID;
  var className = Props.className;
  var style = Props.style;
  var match = Props.navBarVisible;
  var navBarVisible = match !== undefined ? match : true;
  var subscribeToMore = Props.subscribeToMore;
  var navigationProps = Props.navigationProps;
  var title = Props.title;
  var headerComponent = Props.headerComponent;
  var children = Props.children;
  React.useEffect((function () {
          if (subscribeToMore !== undefined) {
            Curry._1(subscribeToMore, /* () */0);
          }
          return ;
        }), ([]));
  var headerTitle;
  var exit = 0;
  if (navigationProps !== undefined) {
    var match$1 = navigationProps[/* headerTitle */1];
    if (match$1 !== undefined) {
      headerTitle = Caml_option.valFromOption(match$1);
    } else {
      exit = 1;
    }
  } else {
    exit = 1;
  }
  if (exit === 1) {
    headerTitle = title !== undefined ? React.createElement(NavigationTitle.make, {
            children: title
          }) : null;
  }
  var className$1 = Cn.make(/* :: */[
        Cn.ifTrue("hui_page_layout_nav_bar_top", navBarVisible),
        /* :: */[
          Cn.unpack(className),
          /* [] */0
        ]
      ]);
  var tmp = {
    className: className$1
  };
  if (style !== undefined) {
    tmp.style = Caml_option.valFromOption(style);
  }
  var tmp$1;
  if (navBarVisible) {
    if (navigationProps !== undefined) {
      var match$2 = navigationProps;
      var tmp$2 = {
        testID: TestID.title,
        headerTitle: headerTitle
      };
      var tmp$3 = match$2[/* fixed */3];
      if (tmp$3 !== undefined) {
        tmp$2.fixed = Caml_option.valFromOption(tmp$3);
      }
      var tmp$4 = match$2[/* headerLeft */0];
      if (tmp$4 !== undefined) {
        tmp$2.headerLeft = Caml_option.valFromOption(tmp$4);
      }
      var tmp$5 = match$2[/* headerRight */2];
      if (tmp$5 !== undefined) {
        tmp$2.headerRight = Caml_option.valFromOption(tmp$5);
      }
      tmp$1 = React.createElement(NavBar.make, tmp$2);
    } else {
      tmp$1 = React.createElement(NavBar.make, {
            testID: TestID.title,
            headerTitle: headerTitle
          });
    }
  } else {
    tmp$1 = null;
  }
  var div = React.createElement("div", tmp, tmp$1, Belt_Option.getWithDefault(headerComponent, null), React.createElement("div", {
            className: "hui_page_layout_main"
          }, children));
  return TestUtils.withTestID(Caml_option.some(testID), div);
}

var make = PageLayout;

var $$default = PageLayout;

export {
  make ,
  $$default ,
  $$default as default,
  
}
/*  Not a pure module */
