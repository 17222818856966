// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE

import * as Icon from "./Icon.bs.js";
import * as Theme from "../Theme.bs.js";
import * as React from "react";
import * as TestID from "../helpers/TestID.bs.js";
import * as Touchable from "./Touchable.bs.js";
import * as Caml_option from "bs-platform/lib/es6/caml_option.js";

function BackIcon(Props) {
  var match = Props.testID;
  var testID = match !== undefined ? match : TestID.goBack;
  var className = Props.className;
  var style = Props.style;
  var onClick = Props.onClick;
  var tmp = {
    testID: testID,
    children: React.createElement(Icon.make, {
          name: /* ArrowBack */512194672,
          color: Theme.colors[/* primary */0]
        })
  };
  if (className !== undefined) {
    tmp.className = Caml_option.valFromOption(className);
  }
  if (style !== undefined) {
    tmp.style = Caml_option.valFromOption(style);
  }
  if (onClick !== undefined) {
    tmp.onClick = Caml_option.valFromOption(onClick);
  }
  return React.createElement(Touchable.make, tmp);
}

var make = BackIcon;

var $$default = BackIcon;

export {
  make ,
  $$default ,
  $$default as default,
  
}
/* Icon Not a pure module */
