// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Avatar from "./Avatar.bs.js";
import * as Caml_option from "bs-platform/lib/es6/caml_option.js";
import * as ThumbWithInterest from "./ThumbWithInterest.bs.js";

function AvatarWithInterest(Props) {
  var testID = Props.testID;
  var className = Props.className;
  var style = Props.style;
  var interest = Props.interest;
  var thumbSize = Props.thumbSize;
  var thumbIconSize = Props.thumbIconSize;
  var url = Props.url;
  var backgroundColor = Props.backgroundColor;
  var tmp = { };
  if (interest !== undefined) {
    tmp.interest = Caml_option.valFromOption(interest);
  }
  if (thumbIconSize !== undefined) {
    tmp.thumbIconSize = Caml_option.valFromOption(thumbIconSize);
  }
  if (thumbSize !== undefined) {
    tmp.thumbSize = Caml_option.valFromOption(thumbSize);
  }
  var icon = React.createElement(ThumbWithInterest.make, tmp);
  var tmp$1 = {
    url: url,
    icon: icon
  };
  if (testID !== undefined) {
    tmp$1.testID = Caml_option.valFromOption(testID);
  }
  if (className !== undefined) {
    tmp$1.className = Caml_option.valFromOption(className);
  }
  if (style !== undefined) {
    tmp$1.style = Caml_option.valFromOption(style);
  }
  if (backgroundColor !== undefined) {
    tmp$1.backgroundColor = Caml_option.valFromOption(backgroundColor);
  }
  return React.createElement(Avatar.make, tmp$1);
}

var make = AvatarWithInterest;

var $$default = AvatarWithInterest;

export {
  make ,
  $$default ,
  $$default as default,
  
}
/* react Not a pure module */
