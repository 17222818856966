import moment from 'moment';

const SHORT_DATE = 'D MMM';
export const SHORT_TIME = 'h:mm A';
const FULL_DATE = `${SHORT_DATE} YYYY`;

export function formatDate(time: string) {
  return moment(time).calendar(undefined, {
    sameElse: `${FULL_DATE} [at] ${SHORT_TIME}`,
  });
}
