/* TypeScript file generated from ContactHeader.re by genType. */
/* eslint-disable import/first */


import * as React from 'react';

const $$toRE888505244: { [key: string]: any } = {"Interested": 0, "NotInterested": 1};

// tslint:disable-next-line:no-var-requires
const ContactHeaderBS = require('./ContactHeader.bs');

import {SpaceContactInterest_t as Types_SpaceContactInterest_t} from '../../src/Types.gen';

import {style as ReactDOMRe_style} from '../../src/shims/ReactDOMRe.shim';

// tslint:disable-next-line:interface-over-type-literal
export type Props = {
  readonly className?: string; 
  readonly hasSentDocs?: boolean; 
  readonly imageUrl: string; 
  readonly interest?: Types_SpaceContactInterest_t; 
  readonly name: string; 
  readonly style?: ReactDOMRe_style; 
  readonly testID?: string; 
  readonly visitCount?: number
};

export const $$default: React.ComponentType<{
  readonly className?: string; 
  readonly hasSentDocs?: boolean; 
  readonly imageUrl: string; 
  readonly interest?: Types_SpaceContactInterest_t; 
  readonly name: string; 
  readonly style?: ReactDOMRe_style; 
  readonly testID?: string; 
  readonly visitCount?: number
}> = function ContactHeader(Arg1: any) {
  const $props = {className:Arg1.className, hasSentDocs:Arg1.hasSentDocs, imageUrl:Arg1.imageUrl, interest:(Arg1.interest == null ? undefined : $$toRE888505244[Arg1.interest]), name:Arg1.name, style:Arg1.style, testID:Arg1.testID, visitCount:Arg1.visitCount};
  const result = React.createElement(ContactHeaderBS.default, $props);
  return result
};

export default $$default;
