// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE

import * as Curry from "bs-platform/lib/es6/curry.js";
import * as React from "react";
import * as TestID from "../helpers/TestID.bs.js";
import * as TestUtils from "../helpers/TestUtils.bs.js";
import * as Belt_Array from "bs-platform/lib/es6/belt_Array.js";
import * as Belt_Option from "bs-platform/lib/es6/belt_Option.js";
import * as Caml_option from "bs-platform/lib/es6/caml_option.js";
import * as ReactEventUtils from "../helpers/ReactEventUtils.bs.js";
import * as ActivityIndicator from "./ActivityIndicator.bs.js";
import * as ContactCarouselItem from "./ContactCarouselItem.bs.js";
import * as ReactInfiniteScroller from "react-infinite-scroller";

require("./ContactsList.css");

function ContactsList$Item(Props) {
  var testID = Props.testID;
  var className = Props.className;
  var style = Props.style;
  var item = Props.item;
  var onContactClick = Props.onContactClick;
  var match = item[/* interested */1];
  var match$1 = item[/* notInterested */2];
  var interest;
  var exit = 0;
  if (match !== undefined && match) {
    interest = /* Interested */0;
  } else {
    exit = 1;
  }
  if (exit === 1) {
    interest = match$1 !== undefined && match$1 ? /* NotInterested */1 : undefined;
  }
  var match$2 = item[/* contact */3];
  var match$3;
  if (match$2 !== undefined) {
    var match$4 = match$2;
    var match$5 = match$4[/* name */0];
    if (match$5 !== undefined) {
      var match$6 = match$4[/* imageUrl */1];
      match$3 = match$6 !== undefined ? /* tuple */[
          match$5,
          match$6
        ] : /* tuple */[
          "",
          ""
        ];
    } else {
      match$3 = /* tuple */[
        "",
        ""
      ];
    }
  } else {
    match$3 = /* tuple */[
      "",
      ""
    ];
  }
  var name = match$3[0];
  var onClick = function (param) {
    if (onContactClick !== undefined) {
      return Curry._1(onContactClick, item);
    } else {
      return /* () */0;
    }
  };
  var testID$1 = Belt_Option.getWithDefault(testID, "" + (String(TestID.spaceContactsGoToSpaceContact) + (":" + (String(name) + ""))));
  var tmp = {
    testID: testID$1,
    name: name,
    imageUrl: match$3[1],
    onClick: onClick
  };
  if (className !== undefined) {
    tmp.className = Caml_option.valFromOption(className);
  }
  if (style !== undefined) {
    tmp.style = Caml_option.valFromOption(style);
  }
  if (interest !== undefined) {
    tmp.interest = Caml_option.valFromOption(interest);
  }
  return React.createElement(ContactCarouselItem.make, tmp);
}

var Item = {
  make: ContactsList$Item
};

function ContactsList(Props) {
  var testID = Props.testID;
  var className = Props.className;
  var style = Props.style;
  var contacts = Props.contacts;
  var onContactClick = Props.onContactClick;
  var match = Props.onEndReached;
  var onEndReached = match !== undefined ? match : ReactEventUtils.noop;
  var match$1 = Props.hasMore;
  var hasMore = match$1 !== undefined ? match$1 : false;
  var loader = React.createElement(ActivityIndicator.make, {
        className: "hui_contact_list_activity",
        key: "loading"
      });
  var tmp = {
    loadMore: onEndReached,
    hasMore: hasMore,
    loader: loader,
    children: React.createElement("div", {
          key: "contact-list-container",
          className: "hui_contact_list_container"
        }, Belt_Array.map(contacts, (function (contact) {
                var tmp = {
                  item: contact,
                  key: contact[/* id */0]
                };
                if (onContactClick !== undefined) {
                  tmp.onContactClick = Caml_option.valFromOption(onContactClick);
                }
                return React.createElement(ContactsList$Item, tmp);
              })))
  };
  if (className !== undefined) {
    tmp.className = Caml_option.valFromOption(className);
  }
  if (style !== undefined) {
    tmp.style = Caml_option.valFromOption(style);
  }
  var scroll = React.createElement(ReactInfiniteScroller.default, tmp);
  return TestUtils.withTestID(Caml_option.some(testID), scroll);
}

var make = ContactsList;

var $$default = ContactsList;

export {
  Item ,
  make ,
  $$default ,
  $$default as default,
  
}
/*  Not a pure module */
