// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE

import * as Cn from "re-classnames/src/Cn.bs.js";
import * as $$Image from "./Image.bs.js";
import * as React from "react";
import * as TestUtils from "../helpers/TestUtils.bs.js";
import * as Belt_Option from "bs-platform/lib/es6/belt_Option.js";
import * as Caml_option from "bs-platform/lib/es6/caml_option.js";

require("./Avatar.css");

function Avatar(Props) {
  var testID = Props.testID;
  var className = Props.className;
  var style = Props.style;
  var backgroundColor = Props.backgroundColor;
  var url = Props.url;
  var icon = Props.icon;
  var className$1 = Cn.make(/* :: */[
        "hui_avatar",
        /* :: */[
          Cn.unpack(className),
          /* [] */0
        ]
      ]);
  var containerStyle = Belt_Option.map(backgroundColor, (function (backgroundColor) {
          return {
                  backgroundColor: backgroundColor
                };
        }));
  var icon$1 = Belt_Option.getWithDefault(icon, null);
  var tmp = {
    className: className$1
  };
  if (style !== undefined) {
    tmp.style = Caml_option.valFromOption(style);
  }
  var tmp$1 = {
    className: "image_container"
  };
  if (containerStyle !== undefined) {
    tmp$1.style = Caml_option.valFromOption(containerStyle);
  }
  var div = React.createElement("div", tmp, React.createElement("div", tmp$1, React.createElement($$Image.make, {
                className: "image",
                src: url,
                alt: "avatar"
              })), React.createElement("div", {
            className: "icon"
          }, icon$1));
  return TestUtils.withTestID(Caml_option.some(testID), div);
}

var make = Avatar;

var $$default = Avatar;

export {
  make ,
  $$default ,
  $$default as default,
  
}
/*  Not a pure module */
