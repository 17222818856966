// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE

import * as DateHelper from "./dateHelper";

function _formatDate(prim) {
  return DateHelper.formatDate(prim);
}

var formatDate = _formatDate;

export {
  formatDate ,
  
}
/* ./dateHelper Not a pure module */
