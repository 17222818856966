// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE

import * as Cn from "re-classnames/src/Cn.bs.js";
import * as React from "react";
import * as TestUtils from "../helpers/TestUtils.bs.js";
import * as Belt_Option from "bs-platform/lib/es6/belt_Option.js";
import * as Caml_option from "bs-platform/lib/es6/caml_option.js";

require("./NavBar.css");

function NavBar(Props) {
  var testID = Props.testID;
  var className = Props.className;
  var style = Props.style;
  var match = Props.fixed;
  var fixed = match !== undefined ? match : true;
  var headerLeft = Props.headerLeft;
  var headerTitle = Props.headerTitle;
  var headerRight = Props.headerRight;
  var className$1 = Cn.make(/* :: */[
        "hui_nav_bar",
        /* :: */[
          Cn.ifTrue("hui_nav_bar_fixed", fixed),
          /* :: */[
            Cn.unpack(className),
            /* [] */0
          ]
        ]
      ]);
  var tmp = {
    className: className$1
  };
  if (style !== undefined) {
    tmp.style = Caml_option.valFromOption(style);
  }
  var div = React.createElement("div", tmp, React.createElement("div", {
            className: "left"
          }, Belt_Option.getWithDefault(headerLeft, null)), React.createElement("div", {
            className: "center"
          }, Belt_Option.getWithDefault(headerTitle, null)), React.createElement("div", {
            className: "right"
          }, Belt_Option.getWithDefault(headerRight, null)));
  return TestUtils.withTestID(Caml_option.some(testID), div);
}

var make = NavBar;

var $$default = NavBar;

export {
  make ,
  $$default ,
  $$default as default,
  
}
/*  Not a pure module */
