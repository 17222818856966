/* TypeScript file generated from CircleIcon.re by genType. */
/* eslint-disable import/first */


import * as React from 'react';

const $$toRE438741426: { [key: string]: any } = {"ThumbsUp": -187524744, "ThumbsDown": 914943167, "Pencil": -318347283, "Attachment": -735835133, "Document": -188437701, "ArrowForwardRound": -668824686, "ArrowBack": 512194672};

// tslint:disable-next-line:no-var-requires
const CircleIconBS = require('./CircleIcon.bs');

import {icon as Icon_icon} from './Icon.gen';

import {style as ReactDOMRe_style} from '../../src/shims/ReactDOMRe.shim';

// tslint:disable-next-line:interface-over-type-literal
export type Props = {
  readonly className?: string; 
  readonly color?: string; 
  readonly iconColor?: string; 
  readonly iconSize?: number; 
  readonly name: Icon_icon; 
  readonly style?: ReactDOMRe_style; 
  readonly testID?: string; 
  readonly thumbSize?: number
};

export const $$default: React.ComponentType<{
  readonly className?: string; 
  readonly color?: string; 
  readonly iconColor?: string; 
  readonly iconSize?: number; 
  readonly name: Icon_icon; 
  readonly style?: ReactDOMRe_style; 
  readonly testID?: string; 
  readonly thumbSize?: number
}> = function CircleIcon(Arg1: any) {
  const $props = {className:Arg1.className, color:Arg1.color, iconColor:Arg1.iconColor, iconSize:Arg1.iconSize, name:$$toRE438741426[Arg1.name], style:Arg1.style, testID:Arg1.testID, thumbSize:Arg1.thumbSize};
  const result = React.createElement(CircleIconBS.default, $props);
  return result
};

export default $$default;
