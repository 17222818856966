/* TypeScript file generated from ContactCarouselItem.re by genType. */
/* eslint-disable import/first */


import * as React from 'react';

const $$toRE888505244: { [key: string]: any } = {"Interested": 0, "NotInterested": 1};

// tslint:disable-next-line:no-var-requires
const ContactCarouselItemBS = require('./ContactCarouselItem.bs');

import {Mouse_t as ReactEvent_Mouse_t} from '../../src/shims/ReactEvent.shim';

import {SpaceContactInterest_t as Types_SpaceContactInterest_t} from '../../src/Types.gen';

import {style as ReactDOMRe_style} from '../../src/shims/ReactDOMRe.shim';

// tslint:disable-next-line:interface-over-type-literal
export type Props = {
  readonly className?: string; 
  readonly imageUrl: string; 
  readonly interest?: Types_SpaceContactInterest_t; 
  readonly name: string; 
  readonly onClick?: (_1:ReactEvent_Mouse_t) => void; 
  readonly style?: ReactDOMRe_style; 
  readonly testID?: string
};

export const $$default: React.ComponentType<{
  readonly className?: string; 
  readonly imageUrl: string; 
  readonly interest?: Types_SpaceContactInterest_t; 
  readonly name: string; 
  readonly onClick?: (_1:ReactEvent_Mouse_t) => void; 
  readonly style?: ReactDOMRe_style; 
  readonly testID?: string
}> = function ContactCarouselItem(Arg1: any) {
  const $props = {className:Arg1.className, imageUrl:Arg1.imageUrl, interest:(Arg1.interest == null ? undefined : $$toRE888505244[Arg1.interest]), name:Arg1.name, onClick:Arg1.onClick, style:Arg1.style, testID:Arg1.testID};
  const result = React.createElement(ContactCarouselItemBS.default, $props);
  return result
};

export default $$default;
