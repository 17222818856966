/* TypeScript file generated from ContactsCarousel.re by genType. */
/* eslint-disable import/first */


import * as React from 'react';

// tslint:disable-next-line:no-var-requires
const ContactsCarouselBS = require('./ContactsCarousel.bs');

import {SpaceContact_t as Types_SpaceContact_t} from '../../src/Types.gen';

import {style as ReactDOMRe_style} from '../../src/shims/ReactDOMRe.shim';

// tslint:disable-next-line:interface-over-type-literal
export type Props = {
  readonly className?: string; 
  readonly contacts: Types_SpaceContact_t[]; 
  readonly onContactClick?: (_1:Types_SpaceContact_t) => void; 
  readonly style?: ReactDOMRe_style; 
  readonly testID?: string
};

export const $$default: React.ComponentType<{
  readonly className?: string; 
  readonly contacts: Types_SpaceContact_t[]; 
  readonly onContactClick?: (_1:Types_SpaceContact_t) => void; 
  readonly style?: ReactDOMRe_style; 
  readonly testID?: string
}> = function ContactsCarousel(Arg1: any) {
  const $props = {className:Arg1.className, contacts:Arg1.contacts.map(function _element(ArrayItem: any) { return [ArrayItem.id, ArrayItem.interested, ArrayItem.notInterested, (ArrayItem.contact == null ? undefined : [ArrayItem.contact.name, ArrayItem.contact.imageUrl])]}), onContactClick:(Arg1.onContactClick == null ? undefined : function (Arg11: any) {
      const result1 = Arg1.onContactClick({id:Arg11[0], interested:Arg11[1], notInterested:Arg11[2], contact:(Arg11[3] == null ? Arg11[3] : {name:Arg11[3][0], imageUrl:Arg11[3][1]})});
      return result1
    }), style:Arg1.style, testID:Arg1.testID};
  const result = React.createElement(ContactsCarouselBS.default, $props);
  return result
};

export default $$default;
