// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE


var colors = /* record */[
  /* primary */"#039DE8",
  /* text */"#000",
  /* lightText */"#FFFFFF",
  /* navBar */"white",
  /* alert */"#FE2851",
  /* border */"#E1E1E4",
  /* dark */"#000000",
  /* light */"#FFFFFF",
  /* darkGray */"#8C8C8C",
  /* yellow */"#FFCD00",
  /* lightGray */"#F0F0F0",
  /* purple */"#AC3EF9",
  /* green */"#03CA1E",
  /* gray */"#CDCED2"
];

var sizes = /* record */[
  /* contentPadding */20,
  /* screenMargin */15,
  /* borderWidth */1,
  /* borderRadiusBase */7
];

export {
  colors ,
  sizes ,
  
}
/* No side effect */
