/* TypeScript file generated from SectionHeader.re by genType. */
/* eslint-disable import/first */


import * as React from 'react';

// tslint:disable-next-line:no-var-requires
const SectionHeaderBS = require('./SectionHeader.bs');

import {Mouse_t as ReactEvent_Mouse_t} from '../../src/shims/ReactEvent.shim';

import {style as ReactDOMRe_style} from '../../src/shims/ReactDOMRe.shim';

// tslint:disable-next-line:interface-over-type-literal
export type Props = {
  readonly className?: string; 
  readonly first?: boolean; 
  readonly onClick?: (_1:ReactEvent_Mouse_t) => void; 
  readonly showAlert?: boolean; 
  readonly style?: ReactDOMRe_style; 
  readonly testID?: string; 
  readonly title: string
};

export const $$default: React.ComponentType<{
  readonly className?: string; 
  readonly first?: boolean; 
  readonly onClick?: (_1:ReactEvent_Mouse_t) => void; 
  readonly showAlert?: boolean; 
  readonly style?: ReactDOMRe_style; 
  readonly testID?: string; 
  readonly title: string
}> = SectionHeaderBS.default;

export default $$default;
