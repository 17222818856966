// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE

import * as Cn from "re-classnames/src/Cn.bs.js";
import * as Theme from "../Theme.bs.js";
import * as React from "react";
import * as TestUtils from "../helpers/TestUtils.bs.js";
import * as Belt_Option from "bs-platform/lib/es6/belt_Option.js";
import * as Caml_option from "bs-platform/lib/es6/caml_option.js";

require("./Icon.css");

function Icon$SVGForName$ThumbsUp(Props) {
  var size = Props.size;
  var color = Props.color;
  return React.createElement("svg", {
              height: String(size),
              width: String(size),
              viewBox: "0 0 24 24",
              xmlns: "http://www.w3.org/2000/svg"
            }, React.createElement("path", {
                  d: "M1 21h4V9H1v12zm22-11c0-1.1-.9-2-2-2h-6.31l.95-4.57.03-.32c0-.41-.17-.79-.44-1.06L14.17 1 7.59 7.59C7.22 7.95 7 8.45 7 9v10c0 1.1.9 2 2 2h9c.83 0 1.54-.5 1.84-1.22l3.02-7.05c.09-.23.14-.47.14-.73v-2z",
                  fill: color
                }));
}

var ThumbsUp = {
  make: Icon$SVGForName$ThumbsUp
};

function Icon$SVGForName$ThumbsDown(Props) {
  var size = Props.size;
  var color = Props.color;
  return React.createElement("svg", {
              height: String(size),
              width: String(size),
              viewBox: "0 0 24 24",
              xmlns: "http://www.w3.org/2000/svg"
            }, React.createElement("path", {
                  d: "M15 3H6c-.83 0-1.54.5-1.84 1.22l-3.02 7.05c-.09.23-.14.47-.14.73v2c0 1.1.9 2 2 2h6.31l-.95 4.57-.03.32c0 .41.17.79.44 1.06L9.83 23l6.59-6.59c.36-.36.58-.86.58-1.41V5c0-1.1-.9-2-2-2zm4 0v12h4V3h-4z",
                  fill: color
                }));
}

var ThumbsDown = {
  make: Icon$SVGForName$ThumbsDown
};

function Icon$SVGForName$Pencil(Props) {
  var size = Props.size;
  var color = Props.color;
  return React.createElement("svg", {
              height: String(size),
              width: String(size),
              viewBox: "0 0 20 20",
              xmlns: "http://www.w3.org/2000/svg"
            }, React.createElement("path", {
                  d: "M14.69,2.661c-1.894-1.379-3.242-1.349-3.754-1.266c-0.144,0.023-0.265,0.106-0.35,0.223l-4.62,6.374l-2.263,3.123\n\tc-0.277,0.382-0.437,0.836-0.462,1.307l-0.296,5.624c-0.021,0.405,0.382,0.698,0.76,0.553l5.256-2.01\n\tc0.443-0.17,0.828-0.465,1.106-0.849l1.844-2.545l5.036-6.949c0.089-0.123,0.125-0.273,0.1-0.423\n\tC16.963,5.297,16.56,4.021,14.69,2.661z M8.977,15.465l-2.043,0.789c-0.08,0.031-0.169,0.006-0.221-0.062\n\tc-0.263-0.335-0.576-0.667-1.075-1.03c-0.499-0.362-0.911-0.558-1.31-0.706c-0.08-0.03-0.131-0.106-0.126-0.192l0.122-2.186\n\tl0.549-0.755c0,0,1.229-0.169,2.833,0.998c1.602,1.166,1.821,2.388,1.821,2.388L8.977,15.465z",
                  fill: color
                }));
}

var Pencil = {
  make: Icon$SVGForName$Pencil
};

function Icon$SVGForName$Attachment(Props) {
  var size = Props.size;
  var color = Props.color;
  return React.createElement("svg", {
              height: String(size),
              width: String(size),
              viewBox: "0 0 20 20",
              xmlns: "http://www.w3.org/2000/svg"
            }, React.createElement("path", {
                  d: "M5.602,19.8c-1.293,0-2.504-0.555-3.378-1.44c-1.695-1.716-2.167-4.711,0.209-7.116c1.391-1.408,6.966-7.053,9.748-9.87\n     c0.988-1,2.245-1.387,3.448-1.06c1.183,0.32,2.151,1.301,2.468,2.498c0.322,1.22-0.059,2.493-1.046,3.493l-9.323,9.44\n     c-0.532,0.539-1.134,0.858-1.738,0.922c-0.599,0.064-1.17-0.13-1.57-0.535c-0.724-0.736-0.828-2.117,0.378-3.337l6.548-6.63\n     c0.269-0.272,0.705-0.272,0.974,0s0.269,0.714,0,0.986l-6.549,6.631c-0.566,0.572-0.618,1.119-0.377,1.364\n     C5.5,15.252,5.66,15.301,5.845,15.28c0.283-0.029,0.606-0.216,0.909-0.521l9.323-9.439c0.64-0.648,0.885-1.41,0.69-2.145\n     c-0.192-0.725-0.778-1.318-1.493-1.513c-0.726-0.197-1.48,0.052-2.12,0.7c-2.782,2.818-8.356,8.462-9.748,9.87\n     c-1.816,1.839-1.381,3.956-0.209,5.143c1.173,1.187,3.262,1.629,5.079-0.212l9.748-9.87c0.269-0.272,0.705-0.272,0.974,0\n     c0.269,0.272,0.269,0.714,0,0.987L9.25,18.15C8.101,19.312,6.814,19.8,5.602,19.8z",
                  fill: color
                }));
}

var Attachment = {
  make: Icon$SVGForName$Attachment
};

function Icon$SVGForName$Document(Props) {
  var size = Props.size;
  var color = Props.color;
  return React.createElement("svg", {
              height: String(size),
              width: String(size),
              viewBox: "0 0 24 24",
              xmlns: "http://www.w3.org/2000/svg"
            }, React.createElement("path", {
                  d: "M6 2c-1.1 0-1.99.9-1.99 2L4 20c0 1.1.89 2 1.99 2H18c1.1 0 2-.9 2-2V8l-6-6H6zm7 7V3.5L18.5 9H13z",
                  fill: color
                }));
}

var $$Document = {
  make: Icon$SVGForName$Document
};

function Icon$SVGForName$ArrowForwardRound(Props) {
  var size = Props.size;
  var color = Props.color;
  return React.createElement("svg", {
              height: String(size),
              width: String(size),
              viewBox: "0 0 24 24",
              xmlns: "http://www.w3.org/2000/svg"
            }, React.createElement("path", {
                  d: "M5 13h11.17l-4.88 4.88c-.39.39-.39 1.03 0 1.42.39.39 1.02.39 1.41 0l6.59-6.59c.39-.39.39-1.02 0-1.41l-6.58-6.6c-.39-.39-1.02-.39-1.41 0-.39.39-.39 1.02 0 1.41L16.17 11H5c-.55 0-1 .45-1 1s.45 1 1 1z",
                  fill: color
                }));
}

var ArrowForwardRound = {
  make: Icon$SVGForName$ArrowForwardRound
};

function Icon$SVGForName$ArrowBack(Props) {
  var size = Props.size;
  var color = Props.color;
  return React.createElement("svg", {
              height: String(size),
              width: String(size),
              viewBox: "0 0 24 24",
              xmlns: "http://www.w3.org/2000/svg"
            }, React.createElement("path", {
                  d: "M20 11H7.83l5.59-5.59L12 4l-8 8 8 8 1.41-1.41L7.83 13H20v-2z",
                  fill: color
                }));
}

var ArrowBack = {
  make: Icon$SVGForName$ArrowBack
};

function Icon$SVGForName(Props) {
  var name = Props.name;
  var color = Props.color;
  var size = Props.size;
  if (name >= -188437701) {
    if (name >= 512194672) {
      if (name >= 914943167) {
        return React.createElement(Icon$SVGForName$ThumbsDown, {
                    size: size,
                    color: color
                  });
      } else {
        return React.createElement(Icon$SVGForName$ArrowBack, {
                    size: size,
                    color: color
                  });
      }
    } else if (name >= -187524744) {
      return React.createElement(Icon$SVGForName$ThumbsUp, {
                  size: size,
                  color: color
                });
    } else {
      return React.createElement(Icon$SVGForName$Document, {
                  size: size,
                  color: color
                });
    }
  } else if (name !== -668824686) {
    if (name >= -318347283) {
      return React.createElement(Icon$SVGForName$Pencil, {
                  size: size,
                  color: color
                });
    } else {
      return React.createElement(Icon$SVGForName$Attachment, {
                  size: size,
                  color: color
                });
    }
  } else {
    return React.createElement(Icon$SVGForName$ArrowForwardRound, {
                size: size,
                color: color
              });
  }
}

var SVGForName = {
  ThumbsUp: ThumbsUp,
  ThumbsDown: ThumbsDown,
  Pencil: Pencil,
  Attachment: Attachment,
  $$Document: $$Document,
  ArrowForwardRound: ArrowForwardRound,
  ArrowBack: ArrowBack,
  make: Icon$SVGForName
};

function stringOfIcon(icon) {
  if (icon >= -188437701) {
    if (icon >= 512194672) {
      if (icon >= 914943167) {
        return "ThumbsDown";
      } else {
        return "ArrowBack";
      }
    } else if (icon >= -187524744) {
      return "ThumbsUp";
    } else {
      return "Document";
    }
  } else if (icon !== -668824686) {
    if (icon >= -318347283) {
      return "Pencil";
    } else {
      return "Attachment";
    }
  } else {
    return "ArrowForwardRound";
  }
}

function Icon(Props) {
  var testID = Props.testID;
  var className = Props.className;
  var style = Props.style;
  var name = Props.name;
  var match = Props.light;
  var light = match !== undefined ? match : false;
  var match$1 = Props.size;
  var size = match$1 !== undefined ? match$1 : 27;
  var color = Props.color;
  var defaultColor = light ? Theme.colors[/* lightText */2] : Theme.colors[/* text */1];
  var color$1 = Belt_Option.getWithDefault(color, defaultColor);
  var className$1 = Cn.make(/* :: */[
        "hui_icon",
        /* :: */[
          Cn.unpack(className),
          /* [] */0
        ]
      ]);
  var tmp = {
    className: className$1
  };
  if (style !== undefined) {
    tmp.style = Caml_option.valFromOption(style);
  }
  var div = React.createElement("div", tmp, React.createElement(Icon$SVGForName, {
            name: name,
            color: color$1,
            size: size
          }));
  var testIDorName = Belt_Option.getWithDefault(testID, stringOfIcon(name));
  return TestUtils.withTestID(testIDorName, div);
}

var make = Icon;

var $$default = Icon;

export {
  SVGForName ,
  stringOfIcon ,
  make ,
  $$default ,
  $$default as default,
  
}
/*  Not a pure module */
