import React, { CSSProperties } from 'react';

type Props = {
  testID?: string;
  className?: string;
  style?: CSSProperties;
};

class ScrollToTop extends React.Component<Props> {
  componentDidMount() {
    if (typeof window !== 'undefined') {
      window.scrollTo(0, 0);
    }
  }

  render() {
    const { children, testID, className, ...props } = this.props;
    return (
      <div data-testid={testID} className={className} {...props}>
        {children}
      </div>
    );
  }
}

export default ScrollToTop;
