// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE


var noContacts = "No contacts";

var noActivities = "No activities";

var interested = "Interested";

var notInterested = "Not Interested";

var visits = "Visits";

var docsSent = "Docs Sent";

var people = "people";

export {
  noContacts ,
  noActivities ,
  interested ,
  notInterested ,
  visits ,
  docsSent ,
  people ,
  
}
/* No side effect */
