// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE


function noop(_event) {
  return /* () */0;
}

export {
  noop ,
  
}
/* No side effect */
