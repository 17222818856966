/* TypeScript file generated from AvatarWithInterest.re by genType. */
/* eslint-disable import/first */


import * as React from 'react';

const $$toRE888505244: { [key: string]: any } = {"Interested": 0, "NotInterested": 1};

// tslint:disable-next-line:no-var-requires
const AvatarWithInterestBS = require('./AvatarWithInterest.bs');

import {SpaceContactInterest_t as Types_SpaceContactInterest_t} from '../../src/Types.gen';

import {style as ReactDOMRe_style} from '../../src/shims/ReactDOMRe.shim';

// tslint:disable-next-line:interface-over-type-literal
export type Props = {
  readonly backgroundColor?: string; 
  readonly className?: string; 
  readonly interest?: Types_SpaceContactInterest_t; 
  readonly style?: ReactDOMRe_style; 
  readonly testID?: string; 
  readonly thumbIconSize?: number; 
  readonly thumbSize?: number; 
  readonly url: string
};

export const $$default: React.ComponentType<{
  readonly backgroundColor?: string; 
  readonly className?: string; 
  readonly interest?: Types_SpaceContactInterest_t; 
  readonly style?: ReactDOMRe_style; 
  readonly testID?: string; 
  readonly thumbIconSize?: number; 
  readonly thumbSize?: number; 
  readonly url: string
}> = function AvatarWithInterest(Arg1: any) {
  const $props = {backgroundColor:Arg1.backgroundColor, className:Arg1.className, interest:(Arg1.interest == null ? undefined : $$toRE888505244[Arg1.interest]), style:Arg1.style, testID:Arg1.testID, thumbIconSize:Arg1.thumbIconSize, thumbSize:Arg1.thumbSize, url:Arg1.url};
  const result = React.createElement(AvatarWithInterestBS.default, $props);
  return result
};

export default $$default;
